import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import styles from "../../../../styles/Services.module.css";
import Img from "../../../../assets/images/MarketingStrategy.png";
const MarketingStrategy = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const sectionStyle = {
    // backgroundImage: `url(${Img})`,
    // backgroundSize: matches ? "" : "contain",
    // backgroundRepeat: "no-repeat",
  };

  return (
    <Grid container alignItems="center">
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{}} order={{}}>
        <img src={Img} width="100%" />
      </Grid>

      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        // sx={{ pb: [1, 1, 20, 20, 20], pt: [60, 60, 0, 0, 0] }}
        order={{}}
      >
        <div className={styles.content}>
          <h1>Marketing Strategy</h1>
          <p>
            Staying competitive today means taking the fullest advantage of a
            variety of strategies, you have to regularly put your service
            marketing strategies under the scanner, asses them, and identify
            areas for improvement. Our experienced and multi disciplined team,
            will help our customers build their sales and profitability with
            marketing strategies focused on developing a mutually rewarding
            relationship with customers. Our team is fully versed in all forms
            of marketing from design, to printing materials, to the press, to
            TV, and of course the internet. You will get a clear outline of your
            business’s personality, values and objectives, so your marketing
            becomes much more than a superficial display. Your advertising and
            messaging, everything your customer sees and hears, reinforces your
            brand in their mind.
          </p>
        </div>
      </Grid>
    </Grid>
  );
};

export default MarketingStrategy;
