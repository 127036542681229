import React from "react";
import Hero from "./sections/Hero";
import Content from "./sections/Content";

const ServicesCreativeDesign = () => {
  return (
    <div>
      <Hero />

      <Content />
    </div>
  );
};

export default ServicesCreativeDesign;
