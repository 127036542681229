import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import Img1 from "../../../../assets/images/branding.png";
import Img2 from "../../../../assets/images/ads.png";
import Img3 from "../../../../assets/images/socialMedia.png";
import Img4 from "../../../../assets/images/editorialDesign.png";

import styles from "../../../../styles/Services.module.css";
const Content = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container style={{ padding: matches ? 40 : 0 }} alignItems="center">
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        order={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
      >
        <img src={Img1} style={{ maxWidth: "100%" }} />
      </Grid>
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        order={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
      >
        <div className={styles.content}>
          <h1>Branding</h1>
          <p>
            When talking about branding design, it typically refers to key brand
            elements such as the logo, color scheme, typography, illustration
            and other design components that makes a brand stand out from
            competitors, and recognizable to consumers. Our creative team will
            help you create a brand identity that perfectly reflects your brand.
            From your logo and color scheme to your typography, each element
            should be created as an integral component to the unified aesthetic
            system that is your brand’s visual identity. You will get a branding
            not only strategic but also creative about your naming and logo
            design, every aspect of your branding should work together to tell a
            story as a whole and throughout various campaigns and usages, while
            it doesn’t all have to look exactly the same, it should fit together
            like pieces of a creative puzzle to form a complete picture.
          </p>
        </div>
      </Grid>

      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        order={{ xs: 4, sm: 4, md: 4, lg: 3, xl: 3 }}
      >
        <div className={styles.content}>
          <h1>Ads Creation</h1>
          <p>
            Every time viewers see an Ad; the brand’s name, message, and logo
            becomes more ingrained in their minds. Once Created, Ads can help
            businesses increase brand awareness, shape its image, promote a
            brand, attract new customers, and it can also increase sales volume.
            Our creative talented team will work together and individually to
            come up with concepts that will have maximum impact as
            advertisements on traditional media, digital media or social
            networks. Keeping the campaign’s objectives at the forefront,
            considering using brand colors, fonts, and messaging that the
            audience will identify as fitting with the business’s image, we
            create Ads that will result in the business’s desired outcome.
            <br />
            Unless we're able to achieve clearly defined objectives, any ad is a
            waste of time and money. What matters is to create authentic
            emotional connections while respecting the intelligence of our
            target audiences, by focusing on four key ideas: understanding,
            attribution, appreciation and appeal.
            <br />
            <br />
            <span>
              {" "}
              Every time viewers see an Ad; the brand’s name, message, and logo
              becomes more ingrained in their minds. Once Created, Ads can help
              businesses increase brand awareness, shape its image, promote a
              brand, attract new customers, and it can also increase sales
              volume. Our creative talented team will work together and
              individually to come up with concepts that will have maximum
              impact as advertisements on traditional media, digital media or
              social networks. Keeping the campaign’s objectives at the
              forefront, considering using brand colors, fonts, and messaging
              that the audience will identify as fitting with the business’s
              image, we create Ads that will result in the business’s desired
              outcome. Unless we're able to achieve clearly defined objectives,
              any ad is a waste of time and money. What matters is to create
              authentic emotional connections while respecting the intelligence
              of our target audiences, by focusing on four key ideas:
              understanding, attribution, appreciation and appeal.
            </span>
          </p>
        </div>
      </Grid>
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        order={{ xs: 3, sm: 3, md: 3, lg: 4, xl: 4 }}
      >
        {" "}
        <img src={Img2} style={{ maxWidth: "100%" }} />
      </Grid>

      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        order={{ xs: 5, sm: 5, md: 5, lg: 5, xl: 5 }}
      >
        {" "}
        <img src={Img3} style={{ maxWidth: "100%" }} />
      </Grid>
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        order={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
      >
        <div className={styles.content}>
          <h1>Social Media Management</h1>
          <p>
            Social media has quickly become one of the most important means of
            connecting with the audience who not only want your brand or
            service, but are ready and willing to spread the word on the behalf
            of your brand. Our team’s expertise in Social media effectively
            harnesses creativity and technology within the social space allowing
            growing your social community using strategic content, and
            establishing campaigns that reinforce brand messaging while
            highlighting initiatives, products, services, events and much more.
            We will work directly with your marketing team to create an
            integrated program that aligns with brand initiatives throughout the
            year. In managing social platform content, we can take care of the
            following activities:
          </p>
        </div>
      </Grid>

      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        order={{ xs: 7, sm: 7, md: 7, lg: 6, xl: 6 }}
      >
        <div className={styles.content}>
          <h1>Editorial Design</h1>
          <p>
            Good Editorial design goes far beyond arranging type, graphics, and
            images on a page, is nothing less than the result of empathy for a
            reader combined with the desire to create beautiful and moving
            experiences. Our experienced team combines fascinating abilities for
            creative typography, smart layouts and clever compositions. By
            focusing on creating and designing appealing visual editorial
            layouts, we engage with the user through effective design, using
            colors, illustrations, layouts and info graphics to better improve
            attention, we can draw the user into the editorial throughvisual
            hierarchy, maintaining this attention through images and
            graphicdesigns. What our clients get isnot only an eye-catching
            editorial design but easy to understand. Our successful designers
            know how tointegrate all the elements in a way that enhances the
            reader’sexperience. By creatingyour publication design that uses
            specific typography, color, and imagery, we can make it clear to
            your audience what your brand is all about.
            <br />
            <br />
            <span>
              Every time viewers see an Ad; the brand’s name, message, and logo
              becomes more ingrained in their minds. Once Created, Ads can help
              businesses increase brand awareness, shape its image, promote a
              brand, attract new customers, and it can also increase sales
              volume. Our creative talented team will work together and
              individually to come up with concepts that will have maximum
              impact as advertisements on traditional media, digital media or
              social networks. Keeping the campaign’s objectives at the
              forefront, considering using brand colors, fonts, and messaging
              that the audience will identify as fitting with the business’s
              image, we create Ads that will result in the business’s desired
              outcome.Unless we're able to achieve clearly defined objectives,
              any ad is a waste of time and money. What matters is to create
              authentic emotional connections while respecting the intelligence
              of our target audiences, by focusing on four key ideas:
              understanding, attribution, appreciation and appeal.
            </span>
          </p>
        </div>
      </Grid>
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        order={{ xs: 6, sm: 6, md: 6, lg: 7, xl: 7 }}
      >
        <img src={Img4} style={{ maxWidth: "100%" }} />
      </Grid>
    </Grid>
  );
};

export default Content;
