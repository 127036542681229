import { Box, Grid } from "@mui/material";
import React from "react";
import Img from "../../../assets/images/homeHero.png";
import styles from "../../../styles/Home.module.css";
const Hero = () => {
  return (
    <div id="home-hero-section" className={styles.heroSection}>
      <Grid
        container
        sx={{ pl: 5, pr: 5, pb: 5 }}
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xl={5} lg={5} md={12} sm={12} xs={12} sx={{}}>
          <h1 className="big-title">Banner title</h1>
          <p className="text">
            We specialize in transforming your ideas, and needs into pioneering,
            sellable products and successful brands. We will help you in all
            phases of the product launch. We leverage our creative departments’
            abilities to discover realistic solutions to complex challenges
            which are vital to the product launching phase.
          </p>
        </Grid>

        <Grid item xl={7} lg={7} md={12} sm={12} xs={12} sx={{}}>
          <Box
            component="img"
            sx={{
              height: "auto",
              width: "100%",
              //   maxHeight: { xs: 0, sm: 1,  md: 2 , lg: 3, xl : 4 },
              //   maxWidth: { xs: 0, sm: 1,  md: 2 , lg: 3, xl : 4 },
            }}
            alt="home image"
            src={Img}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Hero;
