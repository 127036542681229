import React from "react";
import Hero from "./sections/Hero";
import MarketingStrategy from "./sections/MarketingStrategy";
import DigitalMarketing from "./sections/DigitalMarketing";
import SocialMediaManagement from "./sections/SocialMediaManagement";

import Img from "../../../assets/images/full.png";
import AdvertisingPR from "./sections/AdvertisingPR";
import MarketingPlanningProcess from "./sections/MarketingPlanningProcess";

const ServicesMarketingConsultancy = () => {
  const sectionStyle = {
    // backgroundImage: `url(${Img})`,
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    // backgroundPositionY: "-180px",
  };

  return (
    <div style={sectionStyle}>
      <Hero />

      <MarketingStrategy />
      <MarketingPlanningProcess />
      <DigitalMarketing />
      <SocialMediaManagement />
      <AdvertisingPR />
    </div>
  );
};

export default ServicesMarketingConsultancy;
