import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import styles from "../../../../styles/Services.module.css";
import Img from "../../../../assets/images/MarketingPlanningProcess.png";
const MarketingPlanningProcess = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Grid container alignItems="center">
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        order={{ xs: 2, sm: 2, md: 1, lg: 1, xl: 1 }}
      >
        <div className={styles.content}>
          <h1>Marketing Planning Process</h1>
          <p>
            Being heard on a regular basis is an important aspect in helping
            brands stay on an audience's mind. At Infinate we help brands, with
            amazing products, services, and presentations, reach-out to their
            target audience to reinforce your mission and vision. Based on our
            strategy map, we are able to focus our action plan on initiatives
            that deliver results. Since brands need a high level approach that
            keeps their stories or campaigns on the eyes and minds of their
            prospective clients, our strategic planning process helps brands
            create and distribute attention grabbing and memorable campaigns, on
            a regular basis.
          </p>
        </div>
      </Grid>

      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{}}
        order={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
      >
        <img src={Img} width="100%" />
      </Grid>
    </Grid>
  );
};

export default MarketingPlanningProcess;
