import React from "react";
import styles from "../../../styles/Home.module.css";
import Grid from "@mui/material/Grid";
import img1 from "../../../assets/images/services/1.png";
import img2 from "../../../assets/images/services/2.png";
import img3 from "../../../assets/images/services/3.png";
import img4 from "../../../assets/images/services/4.png";
import ItemCard from "../../../components/homeServices/ItemCard";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const Services = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const all_services = [
    {
      id: 1,
      name: "Business Development",
      route: "/services/business-development",
      description: [
        "Product Launching",
        "Business Strategy",
        "Business Architecture",
      ],
      image: img1,
    },

    {
      id: 2,
      name: "Creative Design",
      route: "/services/creative-design",
      description: [
        "Branding",
        "Ads Creation",
        "Motion Graphics",
        "Editorial Design",
      ],
      image: img2,
    },

    {
      id: 3,
      name: "Marketing Consultancy",
      route: "/services/marketing-consultancy",
      description: [
        "Marketing Strategy",
        "Marketing Planning Process",
        "Digital Marketing",
        "Social Media Management",
        "Advertising & PR",
      ],
      image: img3,
    },

    {
      id: 4,
      name: "Mobile Application & Website Development",
      route: "/services/mobile-application-and-website-development",
      description: ["Mobile Application", "Website", "S.E.O"],
      image: img4,
    },
  ];
  return (
    <div id="services" className={styles.servicesSection}>
      <div className={styles.servicesTitle}>
        <h1>OUR</h1>
        <h1>Services</h1>
      </div>

      <Grid container sx={{ padding: matches ? 10 : 0 }}>
        {all_services?.map((service, index) => (
          <Grid key={index} item xl={6} lg={6} md={12} sm={12} xs={12} sx={{}}>
            <ItemCard service={service} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Services;
