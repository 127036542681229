import { Grid } from "@mui/material";
import React from "react";
import styles from "../../../../styles/Services.module.css";
import Img from "../../../../assets/images/AdvertisingPR.png";

const AdvertisingPR = () => {
  return (
    <Grid
      container
      className={styles.servicesBusinessStrategySection}
      alignItems="center"
    >
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{}}>
        <img src={Img} width="100%" />
      </Grid>

      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{}}
        // container
        // alignContent={"center"}
      >
        <div className={styles.content}>
          <h1>Advertising & PR</h1>
          <p style={{ marginBottom: 100 }}>
            Advertising doesn’t merely promote your service marketing. It also
            plays a major role to drive content downloads, thereby increasing
            both visibility and expertise. A successful public relations
            campaign requires strategy, direction, and focus. Our communication
            and marketing experts help brands nurture their credibility. We
            identify and analyze short and long term goals, as well as examine
            where the organization stands right now. We believe that PR or
            public relations is about maintaining a public image for a business
            that reflects its ethos and beliefs Whatever the size of your
            budget, we’ll ensure that your plans maximize exposure to your
            messages, enabling you to engage cost-effectively with the people
            you need to reach. Whether your PR is to build rapport with
            employees, customers, investors, voters, fans or the general public
            we can help
            <br />
            <br />
            <span>
              In a commodity driven market, we always deliver the sharpest
              prices, but by being media neutral, we don’t chase ‘Cheap’ media
              opportunities, we choose those which are ‘Value Rich’.
            </span>
            <br />
            <br />
            <br />
            <span>
              {" "}
              You can outsource all your marketing activity to us or we can
              simply help out in the areas where you need most assistance.
            </span>
          </p>
        </div>
      </Grid>
    </Grid>
  );
};

export default AdvertisingPR;
