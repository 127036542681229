import { Grid } from "@mui/material";
import React from "react";
import styles from "../../../../styles/Services.module.css";
import Img from "../../../../assets/images/servicesBusinessArchitecture.png";

const BusinessArchitecture = () => {
  return (
    <Grid
      container
      className={styles.servicesBusinessArchitectureSection}
      alignItems="center"
    >
      <Grid
        item
        xl={7}
        lg={7}
        md={7}
        sm={12}
        xs={12}
        sx={{}}
        order={{ xs: 2, sm: 2, md: 1, lg: 1, xl: 1 }}
      >
        <div className={styles.businessArchitectureContent}>
          <h1>Business Architecture</h1>
          <p>
            Getting your Business Architecture right is a crucial step in
            preparing for future growth, selling the business or IPO.If you have
            a multi-divisional business, sub-brands or a portfolio of
            products/services within your company, a robust Business
            Architecture helps you structure and manage them.We will help you to
            develop a simple, logical plan for organizing all of your brands.
            This helps you get maximum value from every customer who buys, and
            bring in more business from untapped market segments.
          </p>
        </div>
      </Grid>

      <Grid
        item
        xl={5}
        lg={5}
        md={5}
        sm={12}
        xs={12}
        sx={{
          marginTop: { xs: 10, sm: 10, md: 0, lg: 0, xl: 0 },
          marginBottom: { xs: 10, sm: 10, md: 0, lg: 0, xl: 0 },
        }}
        order={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
      >
        <img src={Img} width="100%" />
      </Grid>
    </Grid>
  );
};

export default BusinessArchitecture;
