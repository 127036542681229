import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import Img from "../../../../assets/images/creativeDesign.png";
import styles from "../../../../styles/Services.module.css";

const Hero = () => {
  const mobile = useMediaQuery("(max-width:600px)");

  const customStyle = {
    backgroundImage: `url(${Img})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: mobile ? "100vh" : "150vh",
    backgroundPosition: "center bottom",
    // position: "absolute",
    // top: 0,
    marginTop: mobile ? -200 : -300,
  };

  return (
    <div style={customStyle}>
      <div
        id="services-creative-design"
        className={styles.hero}
        style={{
          padding: mobile ? 10 : 40,
          minHeight: mobile ? "100vh" : "150vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h1>Services</h1>
        <h1>Creative Design</h1>

        <p>
          It is the quality of design within your marketing materials that will
          set you ahead of the rest. The way that these images and words are
          presented establishes a quality design service from something
          unsubstantial. Our team is experts at delivering show stopping
          professional designs for all reasons from the Logo to worldwide
          branding. We can apply our designs to all types of media, through
          websites, press adverts, posters and business cards. Professional
          design has to be beyond pretty pictures, there needs to be reason and
          meaning within every design we create for our clients, it is for this
          reason that for many years we have customers that return to our design
          service time after time.
        </p>
      </div>
    </div>
  );
};

export default Hero;
