import { Grid } from "@mui/material";
import React from "react";
import styles from "../../../../styles/Services.module.css";
import Img from "../../../../assets/images/DigitalMarketing.png";
const DigitalMarketing = () => {
  return (
    <Grid container alignItems="center">
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        {" "}
        <img src={Img} width="100%" />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <div className={styles.content}>
          <h1>Digital Marketing</h1>
          <p>
            Digital marketing has dominated the marketing landscape and is more
            or less the future of marketing. Our team consists of experts who
            are the finest in their field which utilize new technology with the
            aim of shaping and boosting brand positioning and movement through
            comprehensive strategic planning and creative insights. What our
            clients get is a team who are creative in their approach, ruthless
            in pursuing a return on investment and objective in recommending
            digital strategies to achieve their commercial and communications
            objectives
          </p>
        </div>
      </Grid>
    </Grid>
  );
};

export default DigitalMarketing;
