import { Grid } from "@mui/material";
import React from "react";
import Img from "../../../../assets/images/servicesMarketingConsultancy.png";
import styles from "../../../../styles/Services.module.css";
const Hero = () => {
  return (
    <Grid container className={styles.servicesHeroSection}>
      <Grid
        item
        xl={7}
        lg={7}
        md={7}
        sm={12}
        xs={12}
        // sx={{ paddingLeft: 40 }}
        sx={{ pl: [1, 1, 1, 4, 4] }}
        // style={{ paddingLeft: 40 }}
        order={{ xs: 2, sm: 2, md: 1, lg: 1, xl: 1 }}
        // container
        // justifyContent="center"
        // alignContent="center"
      >
        <div id="services-marketing-consultancy" className={styles.hero}>
          <h1>Services</h1>
          <h1>Marketing</h1>

          <h1>Consultancy</h1>
          <p>
            If your potential customers do not know you exist, or know what you
            offer then your business is unlikely to reach its full potential.
            FACT! At Infinate we create integrated marketing campaigns that
            drive value. We enable cross-functional programs to support every
            client's growth strategy and brand ambitions. Our marketing programs
            target, attract, and motivate your audience.From campaign and brand
            management to strategic planning and digital marketing, we use the
            tools that are most effective for your campaign. We take care of all
            your needs, including:
          </p>
        </div>
      </Grid>
      <Grid
        item
        xl={5}
        lg={5}
        md={5}
        sm={12}
        xs={12}
        sx={{ minHeight: "100vh" }}
        order={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
      >
        <img
          src={Img}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            height: "100vh",
            zIndex: -1,
          }}
        />{" "}
      </Grid>
    </Grid>
  );
};

export default Hero;
