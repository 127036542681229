import { Grid } from "@mui/material";
import React from "react";
import Img from "../../assets/images/contact.png";
import styles from "../../styles/Contact.module.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useState } from "react";
const Contact = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API}/user/contact/sendMessage`, data)
      .then((res) => {
        reset();
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid container spacing={2} style={{ paddingLeft: 50, paddingRight: 50 }}>
      <Grid item xl={5} lg={5} md={5} sm={12} xs={12} container>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <h1>Contact Us</h1>
          <input
            placeholder="Full Name"
            {...register("full_name", { required: true })}
            className={errors.full_name && styles.hasError}
          />
          <input
            placeholder="Email"
            {...register("email", { required: true })}
            className={errors.email && styles.hasError}
          />
          <input
            placeholder="Subject"
            {...register("subject", { required: true })}
            className={errors.subject && styles.hasError}
          />
          <textarea
            placeholder="Message"
            {...register("message", { required: true })}
            cols="40"
            rows="5"
            className={errors.message && styles.hasError}
          ></textarea>
          {loading ? (
            <div className="loader"></div>
          ) : (
            <input type="submit" className={styles.submitBtn} />
          )}
        </form>
      </Grid>
      <Grid
        item
        xl={7}
        lg={7}
        md={7}
        sm={12}
        xs={12}
        style={{ position: "relative" }}
      >
        <img src={Img} className={styles.img} />
      </Grid>
    </Grid>
  );
};

export default Contact;
