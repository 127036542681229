import { Grid } from "@mui/material";
import React from "react";
import styles from "../../../../styles/Services.module.css";
import Img from "../../../../assets/images/servicesBusinessStrategy.png";

const BusinessStrategy = () => {
  const steps = [
    "● Industry Research & Analysis",
    "● Strategic Positioning Materials and Positioning Statements",
    "● Strategic Partnerships & Co-Branding Opportunities",
    "● Spokesperson Management",
    "● Trade & Retailer Relations",
    "● Forecasting",
  ];
  return (
    <Grid container className={styles.servicesBusinessStrategySection}>
      <Grid item xl={5} lg={5} md={5} sm={12} xs={12} sx={{}}>
        <img src={Img} width={"100%"} />
      </Grid>

      <Grid
        item
        xl={7}
        lg={7}
        md={7}
        sm={12}
        xs={12}
        sx={{}}
        // container
        // alignContent={"center"}
      >
        <div className={styles.businessStrategyContent}>
          <h1>Business Strategy</h1>
          <p style={{ marginBottom: 100 }}>
            You already know you have to position your business as distinctly
            different from the competition. But how do you achieve that, and
            appear the most relevant to customers?This is our aim when
            developing your business strategy, we identify and analyze short and
            long term goals, as well as examine where the organization stands
            right now.This allows us to take a holistic approach to your
            business needs, and it enables us to determine the most effective
            path forward, which includes:
          </p>
          {steps.map((step, i) => (
            <p key={i}>{step}</p>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default BusinessStrategy;
