import React from "react";
import styles from "../../../styles/Home.module.css";
import img1 from "../../../assets/images/approaches/1.png";
import img2 from "../../../assets/images/approaches/2.png";
import img3 from "../../../assets/images/approaches/3.png";
import img4 from "../../../assets/images/approaches/4.png";
import img5 from "../../../assets/images/approaches/5.png";
import Steps from "../../../components/homeApproch/Steps";

const Approach = () => {
  const approaches = [
    {
      id: 1,
      name: "Initial Meeting",
      description:
        "The most important step with any clients is a detailed briefing meeting where we take time to establish a clear understanding of their business, the sector, the key issues and objectives. We then agree on the exact scope of the work required.", // note: "This initial meeting is on a non commitment basis.",
      color: "rgba(204, 108, 216, 1)",
      image: img1,
    },

    {
      id: 2,
      name: "Plan Preparation",
      description:
        "After the initial meeting we clarify to ensure all key issues and objectives have captured accurately and that the scope of the work is correct. A Plan is then written, which is designed to meet the objectives as outlined.",
      image: img2,
    },

    {
      id: 3,
      name: "Plan & Budget Presentation",
      description:
        "Once the Plan is review and agreed, a detailed action plan is written, which outlines in detail the schedule of tasks, the responsibilities and budgets to support this plan.",
      image: img3,
    },

    {
      id: 4,
      name: "Plan Approval",
      description:
        "The detail in this plan is reviewed and agreed with the client, including the timing of activities, the responsibilities with modifications as required.",
      image: img4,
    },

    {
      id: 5,
      name: "Plan Execution",
      description:
        "Once the plan has been agreed, Infinate will than oversee the execution of it working with the client every step of the way. Infinate become a Partner with the client by taking responsibility for the process, leaving the client to concentrate on running their business. At regular intervals during the project we review progress with the client. The objective is to ensure that the Plan is being adhered to, that the activities are having the desired results and a check to see if circumstances have changed in any way.",
      // note: "The marketplace is dynamic and it is vital that we constantly review the progress, so that plans can be flexed accordingly during the course of the project.",
      color: "rgba(18, 194, 233, 1)",
      image: img5,
    },
  ];

  return (
    <div id="home-approach-section" className={styles.approachSection}>
      <div className={styles.approachTitle}>
        <h1>OUR</h1>
        <h1>Approach</h1>
      </div>
      <Steps approaches={approaches} />
    </div>
  );
};

export default Approach;
