import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Container, Typography } from "@mui/material";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient(180deg, #12C6BC 0%, #325CA8 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient(180deg, #12C6BC 0%, #325CA8 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 115,
    border: 0,
    width: 2,
    // marginLeft: 65,
    marginLeft: "max(calc(65px / 1.8), calc((5.8vw * 65) / 100))",
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  //   backgroundColor:
  //     theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  //   color: "#fff",
  //   width: 157,
  //   height: 157,
  //   display: "flex",
  //   borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  //   ...(ownerState.active && {
  //     backgroundImage: "linear-gradient(180deg, #12C6BC 0%, #325CA8 100%)",
  //     boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  //   }),
  //   ...(ownerState.completed && {
  //     backgroundImage: "linear-gradient(180deg, #12C6BC 0%, #325CA8 100%)",
  //   }),
}));

const StyledStepLabel = styled(StepLabel)({
  "& .MuiStepLabel-label": {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "500 !important",

    fontSize: "max(calc(32px / 1.8), calc((5.8vw * 32) / 100))",
    color: "#000000",

    "& p": {
      fontSize: "max(calc(20px / 1.8), calc((5.8vw * 20) / 100))",

      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: "500",

      color: "#636363",
    },
  },
});

export default function Steps({ approaches }) {
  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
    const imgStyle = {
      //   width: 157,
      width: "max(calc(157px / 1.8), calc((5.8vw * 157) / 100))",
      objectFit: "contain",
      height: "max(calc(157px / 1.8), calc((5.8vw * 157) / 100))",
    };
    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {/* {icons[String(props.icon)]} */}
        <img style={imgStyle} src={approaches[String(props.icon - 1)]?.image} />
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  return (
    <Container maxWidth="md" style={{ margin: "130px auto 150px auto" }}>
      {/* <Stack sx={{ padding: 10, margin: "auto" }} spacing={4}> */}
      <Stepper
        orientation="vertical"
        // alternativeLabel
        activeStep={approaches.length}
        connector={<ColorlibConnector />}
      >
        {approaches.map((approach, index) => (
          <Step
            style={{ position: "relative" }}
            expanded={true}
            active={true}
            key={index}
          >
            <StyledStepLabel StepIconComponent={ColorlibStepIcon}>
              {approach.name}

              <Typography>{approach.description}</Typography>

              <Typography
                style={{
                  position: "absolute",
                  top: "110%",
                  color: approach.color,
                  fontStyle: "italic",
                }}
              >
                {approach.note ?? ""}
              </Typography>
            </StyledStepLabel>
          </Step>
        ))}
      </Stepper>
      {/* </Stack> */}
    </Container>
  );
}
