import React from "react";
import Hero from "./sections/Hero";
import ProductLaunching from "./sections/ProductLaunching";
import BusinessStrategy from "./sections/BusinessStrategy";
import BusinessArchitecture from "./sections/BusinessArchitecture ";

const Services = () => {
  return (
    <div>
      <Hero />

      <ProductLaunching />
      <BusinessStrategy />
      <BusinessArchitecture />
    </div>
  );
};

export default Services;
