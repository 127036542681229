import { Grid } from "@mui/material";
import React from "react";
import Img from "../../../../assets/images/servicesBusinessDev.png";
import styles from "../../../../styles/Services.module.css";
const Hero = () => {
  const leftSectionStyle = {
    // backgroundImage: `url(${Img})`,
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
  };
  return (
    <Grid container className={styles.servicesHeroSection}>
      <Grid
        style={leftSectionStyle}
        item
        xl={5}
        lg={5}
        md={5}
        sm={12}
        xs={12}
        sx={{ minHeight: "100vh" }}
      >
        <img
          src={Img}
          style={{
            position: "absolute",
            top: 0,
            height: "100vh",
            zIndex: -1,
          }}
        />{" "}
      </Grid>
      <Grid
        item
        xl={7}
        lg={7}
        md={7}
        sm={12}
        xs={12}
        sx={{}}
        // container
        // justifyContent="center"
        // alignContent="center"
      >
        <div
          id="services-business-development"
          className={`${styles.businessDEV} ${styles.hero}`}
        >
          <h1>Services</h1>
          <h1>Business</h1>
          <h1>Development</h1>
          <p>
            Our Business development and concept creation method is unbeatable.
            We are not only aware of the new business and environment, we’ve
            crafted a firm strategic approach to interpret key insights,
            objectives and goals of the business into a strong brand identity
            enabling organizations to connect their brand with their target
            consumers.
            <br />
            <br />
            {/* As specialist business consultants we are able to flex the service
            we provide dependent on your needs. */}
          </p>
        </div>
      </Grid>
    </Grid>
  );
};

export default Hero;
