import axios from "axios";
import { createBrowserHistory } from "history";
import cookie from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes as ReactRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import allActions from "./actions/allActions";
import Layout from "./components/layout";
import Home from "./pages/home";
import ServicesBusinessDevelopment from "./pages/services/businessDevelopment";
import ServicesMarketingConsultancy from "./pages/services/marketingConsultancy";
import ServicesCreativeDesign from "./pages/services/CreativeDesign";
import ServicesAppWebDev from "./pages/services/AppWebDev";
import About from "./pages/about";
import Contact from "./pages/contact";

const hist = createBrowserHistory();

function App() {
  const [user, setUser] = useState("");
  const token = cookie.get("token");

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);

  const Loader = () => (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  // useEffect(() => {
  //   const vaildateUser = async () => {
  //     // verify a token symmetric
  //     axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  //     const user_type =
  //       localStorage.getItem("user_type") == 0
  //         ? "admin/auth/admin-profile"
  //         : "user/auth/user-profile";
  //     setUser(user_type);
  //     await axios
  //       .get(`${process.env.REACT_APP_API}/${user_type}`)
  //       .then((res) => {
  //         dispatch(allActions.userActions.setUser(res.data));
  //       });
  //   };
  //   if (token) {
  //     vaildateUser();
  //   }
  // }, [token]);

  // useEffect(() => {
  //   let user = JSON.parse(localStorage.getItem("user"));
  //   let token = localStorage.getItem("token");

  //   if (user && token) {
  //     axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  //     dispatch(allActions.userActions.setUser(user));
  //     cookie.set("token", token);
  //   }
  // }, []);

  return (
    <BrowserRouter history={hist}>
      <React.Suspense fallback={<Loader />} />
      <ToastContainer />
      <Layout>
        <ReactRoutes>
          <Route path="*" element={<Home />} />
          <Route
            path="/services/business-development"
            element={<ServicesBusinessDevelopment />}
          />
          <Route
            path="/services/marketing-consultancy"
            element={<ServicesMarketingConsultancy />}
          />

          <Route
            path="/services/creative-design"
            element={<ServicesCreativeDesign />}
          />

          <Route
            path="/services/mobile-application-and-website-development"
            element={<ServicesAppWebDev />}
          />

          <Route path="/about" element={<About />} />

          <Route path="/contact" element={<Contact />} />

          {/* <Route path="/" exact element={<AdminLogin />} />
        <Route path="/admin" exact element={<AdminLogin />} /> */}
          {/* {currentUser.loggedIn === true && !currentUser.user.user_type ? (
          <>
            <Route path="/admin/homepage" exact element={<AdminHomePage />} />
            <Route path="/admin/countries" exact element={<AdminCountries />} />
            <Route
              path="/admin/categories"
              exact
              element={<AdminCategories />}
            />
            <Route
              path="/admin/subscription-types"
              exact
              element={<AdminSubscriptionTypes />}
            />

            <Route
              path="/admin/hotdeals-offers"
              exact
              element={<AdminHotDealsOffers />}
            />
          </>
        ) : null} */}
        </ReactRoutes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
