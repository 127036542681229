import { Grid } from "@mui/material";
import React from "react";
import styles from "../../styles/About.module.css";
const WhoWeAre = ({ data }) => {
  return (
    <Grid container>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <div
          id="about-who-we-are"
          className={styles.main}
          style={{ paddingLeft: 40 }}
        >
          <h1>Who</h1>
          <h1>We Are</h1>

          <p>
            {/* We are a group of creative experts strongly defined by values; our
            team combines local and regional knowledge with many years of
            experience in major GCC & MENA agencies, working at senior levels
            within the business and media. We always up-skill ourselves on new
            business techniques to combat and defeat the complexity of today’s
            ever-changing online and technological advancements that will affect
            the market, always trying our best to operate in a way to be
            socially responsible, choosing people and projects that make a
            difference. */}
            {data}
          </p>
        </div>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}></Grid>
    </Grid>
  );
};

export default WhoWeAre;
