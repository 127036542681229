import React from "react";
import { Grid } from "@mui/material";
import styles from "../../styles/Services.module.css";
import Img from "../../assets/images/culture.png";
const Culture = () => {
  return (
    <Grid container className={styles.aboutCulture} alignItems="center">
      <Grid
        item
        xl={7}
        lg={7}
        md={7}
        sm={12}
        xs={12}
        sx={{}}
        order={{ xs: 2, sm: 2, md: 1, lg: 1, xl: 1 }}
      >
        <div className={styles.hero} style={{ padding: 10 }}>
          <h1>OUR</h1>
          <h1>Culture</h1>
          <p>
            Our passion is consulting for all aspects of business, marketing and
            Media and it’s devoted to helping others succeed in their business
            efforts. Whether it is consulting, managing an entire project or
            just participating with a small part of a project, you will
            dramatically increase your success results. Our culture is to build
            durable and trusting relationships with our clients based on
            outstanding customer care and consistently great results.
          </p>
        </div>
      </Grid>

      <Grid
        item
        xl={5}
        lg={5}
        md={5}
        sm={12}
        xs={12}
        sx={{
          marginTop: { xs: 10, sm: 10, md: 0, lg: 0, xl: 0 },
          marginBottom: { xs: 10, sm: 10, md: 0, lg: 0, xl: 0 },
        }}
        order={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
      >
        <img src={Img} width="100%" />
      </Grid>
    </Grid>
  );
};

export default Culture;
