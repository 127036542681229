import { Grid } from "@mui/material";
import React from "react";
import styles from "../../styles/About.module.css";
const WhatWeDo = ({ data }) => {
  return (
    <>
      <div
        id="about-what-we-do"
        className={styles.main}
        style={{ textAlign: "center", marginTop: 200, marginBottom: 100 }}
      >
        <h1>What</h1>
        <h1>We Do</h1>
      </div>

      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <div className={styles.weDoSection}>
            <span>1</span>
            <p>
              {/* We always think of new ideas, develop creative solutions for small
              and big brands alike, build authentic product identities, and much
              more. */}
              {data[0]}
            </p>
          </div>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <div className={styles.weDoSection}>
            <span>2</span>
            <p>
              {/* Whether it’s to launch a new product, service, push sales for a
              client, business-to-business campaign, advertising plan, marketing
              plan, social media strategy, Branding, Corporate ID, Website &
              Mobile App design and development. Whatever your requirements, we
              will make sure that we adapt our processes to suit you. */}
              {data[1]}
            </p>
          </div>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <div className={styles.weDoSection}>
            <span>3</span>
            <p>
              {/* Whether it’s to launch a new product, service, push sales for a
              client, business-to-business campaign, advertising plan, marketing
              plan, social media strategy, Branding, Corporate ID, Website &
              Mobile App design and development. Whatever your requirements, we
              will make sure that we adapt our processes to suit you. */}
              {data[2]}
            </p>
          </div>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <div className={styles.weDoSection}>
            <span>4</span>
            <p>
              {/* Whether it’s to launch a new product, service, push sales for a
              client, business-to-business campaign, advertising plan, marketing
              plan, social media strategy, Branding, Corporate ID, Website &
              Mobile App design and development. Whatever your requirements, we
              will make sure that we adapt our processes to suit you. */}
              {data[3]}
            </p>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default WhatWeDo;
