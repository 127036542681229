import React from "react";
import styles from "../../styles/About.module.css";
const Hero = ({ data }) => {
  return (
    <div className={styles.aboutHero}>
      <p>
        {/* Infinate is a full Service Business Consultant Agency offering a
        combination of research, strategy, creativity, design, art, technology,
        marketing and business services to our clients. Simply, we don’t
        consider our clients as just clients; instead we consider ourselves as
        partners and indulge ourselves into understanding their business. We try
        our best to deliver a complete business-building strategic vision and
        implement it in a creative manner. */}
        {data}
      </p>
    </div>
  );
};

export default Hero;
