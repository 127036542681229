import { useMediaQuery } from "@mui/material";
import React from "react";

const GradientCard = ({ imgSrc, content }) => {
  const mobile = useMediaQuery("(max-width:600px)");
  const divStyle = {
    background: "linear-gradient(109.4deg, #6EC1B3 -23.27%, #2374BA 127.72%)",
    padding: 20,
    margin: mobile ? 35 : 60,
    marginBottom: "10px",
    marginTop: "10px",
    borderRadius: "19px",
  };

  const pStyle = {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "500",

    fontSize: "max(calc(18px / 1.8), calc((5.8vw * 18) / 100))",

    color: "#FFFFFF",
  };
  return (
    <div style={divStyle}>
      <img src={imgSrc} style={{ marginBottom: 15, width: 70 }} />
      <p style={pStyle}>{content}</p>
    </div>
  );
};

export default GradientCard;
