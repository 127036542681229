import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import styles from "../../styles/Layout.module.css";
// import Img from "../../images/footer-bg.png";

import TikTok from "../../assets/icons/tiktok.svg";
import Fb from "../../assets/icons/fb.svg";
import Insta from "../../assets/icons/insta.svg";

const Footer = () => {
  const mobile = useMediaQuery("(max-width:600px)");

  const navItems = [
    { id: 1, name: "Home", route: "/" },
    { id: 2, name: "About us", route: "/about" },
    { id: 3, name: "Contact Us", route: "/contact" },
  ];

  const footerItems = [
    { id: 1, name: "Services", route: "/#services" },
    {
      id: 2,
      name: "Creative Design",
      route: "/services/marketing-consultancy",
    },
    {
      id: 3,
      name: "Business Development",
      route: "/services/business-development",
    },
    {
      id: 4,
      name: "Marketing Consultancy",
      route: "/services/marketing-consultancy",
    },
    {
      id: 5,
      name: "Mobile App & Web Development",
      route: "/services/mobile-application-and-website-development",
    },
  ];

  return (
    <Grid
      container
      style={{
        paddingTop: 50,
        paddingRight: 0,
        paddingBottom: 100,
        paddingLeft: mobile ? 35 : 70,

        background: "#000C16",
      }}
    >
      <Grid
        item
        xl={4}
        lg={4}
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {navItems.map((item, index) => (
          <Button
            className={styles.footerLink}
            key={index}
            sx={{ color: "#fff", justifyContent: "flex-start" }}
            component={Link}
            to={item.route}
          >
            {item.name}
          </Button>
        ))}
      </Grid>
      <Grid
        item
        xl={8}
        lg={8}
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {footerItems.map((item, index) => (
          // <Button
          //   className={styles.footerLink}
          //   key={index}
          //   sx={{ color: "#fff", justifyContent: "flex-start" }}
          //   component={Link}
          //   to={item.route}
          // >
          //   {item.name}
          // </Button>

          <Button className={styles.footerLink} key={index}>
            <a
              style={{
                color: "#fff",
                textDecoration: "none",
                textAlign: "left",
              }}
              href={item.route}
            >
              {item.name}
            </a>
          </Button>
        ))}
      </Grid>
    </Grid>
  );
};

export default Footer;
