import React from "react";
import Hero from "./sections/Hero";
import Content from "./sections/Content";

const ServicesAppWebDev = () => {
  return (
    <div>
      <Hero />
      <Content />
    </div>
  );
};

export default ServicesAppWebDev;
