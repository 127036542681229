import React from "react";
import Hero from "./Hero";
import WhoWeAre from "./WhoWeAre";
import WhatWeDo from "./WhatWeDo";
import useFetch from "../../customHooks/useFetch";
import Culture from "./Culture";

const About = () => {
  const { data, error, loading } = useFetch(
    `${process.env.REACT_APP_API}/user/about/get`
  );

  return (
    <div style={{ paddingBottom: 200 }}>
      <Hero data={data?.intro} />
      <WhoWeAre data={data?.who_we_are} />
      <WhatWeDo
        data={[
          data?.what_we_do_1,
          data?.what_we_do_2,
          data?.what_we_do_3,
          data?.what_we_do_4,
        ]}
      />
      <Culture />
    </div>
  );
};

export default About;
