import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
const ItemCard = ({ service }) => {
  const Item = styled(Paper)(() => ({
    background: "#FFFFFF",
    border: "1px solid #B8F2FF",
    borderRadius: "21px",
    padding: "50px 0px 50px 40px",
    textAlign: "left",
    color: "black",
    margin: 20,
  }));

  const titleStyle = {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "500",
    // fontSize: "24px",
    fontSize: "max(calc(24px / 1.5), calc((5.8vw * 24) / 100))",
    textAlign: "left",
    color: "#000000",
  };

  const listStyle = {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "max(calc(16px / 1.5), calc((5.8vw * 16) / 100))",
    color: "#636363",
  };

  const imgStyle = {
    width: "max(calc(157px / 1.8), calc((5.8vw * 157) / 100))",
    height: "max(calc(157px / 1.8), calc((5.8vw * 157) / 100))",
  };

  const mobile = useMediaQuery("(max-width:600px)");

  const StyledLink = styled(Link)`
    text-decoration: none;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  `;

  return (
    <Item elevation={3} style={{ padding: mobile && "20px" }}>
      {mobile ? (
        <StyledLink
          to={service.route}
          style={{ display: "", alignItems: "center" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={service.image} style={imgStyle} />
            <h1 style={{ ...titleStyle, marginLeft: 20 }}>{service.name}</h1>
          </div>
          <div style={{ paddingTop: 20 }}>
            <ul className="two-column-div">
              {service.description.map((item, index) => (
                <li key={index} style={listStyle}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </StyledLink>
      ) : (
        <StyledLink
          to={service.route}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={service.image} style={imgStyle} />
          <div style={{ paddingLeft: 35 }}>
            <h1 style={titleStyle}>{service.name}</h1>
            <ul className="two-column-div">
              {service.description.map((item, index) => (
                <li key={index} style={listStyle}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </StyledLink>
      )}{" "}
    </Item>
  );
};

export default ItemCard;
