import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import Img1 from "../../../../assets/images/mobileApps.png";
import Img2 from "../../../../assets/images/webDev.png";
import Img3 from "../../../../assets/images/seo.png";
import styles from "../../../../styles/Services.module.css";
const Content = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const technical = [
    "● Site Structure",
    "● Structured data",
    "● Website discoverability",
    "● Website keyword analysis",
    "● Search indexation",
    "● Search snippets",
    "● Meta data analysis",
  ];

  const strategy = [
    "● Onsite content analysis",
    "● Inbound link analysis",
    "● Additional page-level analysis",
    "● Social media elements",
  ];
  return (
    <Grid container style={{ padding: matches ? 40 : 0 }}>
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        order={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
      >
        <img src={Img1} style={{ maxWidth: "100%" }} />
      </Grid>
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        order={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
      >
        <div className={styles.content}>
          <h1>Mobile Apps</h1>
          <p>
            The difference between a good App and a bad App is usually the
            quality of its user experience (UX). Today, mobile users expect a
            lot from an App: fast loading time, ease of use and delight during
            interaction. Our mobile application services customize your idea
            into a ready-to-use App by carefully managing all the layers
            involved in a project: from the business side to the technical side,
            and user interface design. Our priority is to always make Apps easy
            to follow and navigate, so our UX specialists sketch layouts and
            wireframes with the users in mind. Thorough usability testing is
            also a part of the UX design stage. Our UI designers make sure that
            the Apps’ design is consistent with your brand image and inherits
            existing company color scheme, fonts and graphic style.
          </p>
        </div>
      </Grid>

      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        order={{ xs: 4, sm: 4, md: 4, lg: 3, xl: 3 }}
      >
        <div className={styles.content}>
          <h1>Website Development</h1>
          <p>
            The appearance of your website is the first impression that you make
            on a potential customer, your website design shouldn’t stop there
            the best design and development will make your site as beautiful as
            it is functional and easy to use. Our full-stack web development
            team, working with all the latest technologies and frameworks, is
            capable of building the best, most responsive website for your
            business. The design and development should respond to the user’s
            behavior and provide access to web pages on different screen sizes,
            platform, and orientation, including engineering, design, content
            development, client liaison, scripting, security and e-commerce
            development. We collaborate with you to plan and execute the details
            of your website so that it connects with your audience, communicates
            your message and elevates your brand all at the same time. It can
            start from a single static page to a comprehensive list of
            applications and social network services. We turn your website into
            an effective way to engage with your audience and a high-performing
            marketing tool for your business.
          </p>
        </div>
      </Grid>
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        order={{ xs: 3, sm: 3, md: 3, lg: 4, xl: 4 }}
      >
        {" "}
        <img src={Img2} style={{ maxWidth: "100%" }} />
      </Grid>

      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        order={{ xs: 5, sm: 5, md: 5, lg: 5, xl: 5 }}
      >
        {" "}
        <img src={Img3} style={{ maxWidth: "100%" }} />
      </Grid>
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        order={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
      >
        <div className={styles.content}>
          <h1>Search Engine Optimizer</h1>
          <p>
            Search Engine Optimizer SEO makes your website easy for users and
            search engine robots to understand. It’s helpful in terms of website
            ranking as well as serving as a promotional tool, which leads to
            higher traffic. Our technical team will help your business be
            recommended by Google, and give users a better experience through
            quick speed and easy navigation. We focus on the following areas to
            deliver a full SEO program:
          </p>
        </div>
        <div className={styles.gradientBox}>
          <div>
            <h1>Technical</h1>
            <ul>
              {technical.map((x, i) => (
                <li key={i}>{x}</li>
              ))}
            </ul>
          </div>

          <div>
            <h1>Strategy</h1>
            <ul>
              {strategy.map((x, i) => (
                <li key={i}>{x}</li>
              ))}
            </ul>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Content;
