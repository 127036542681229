import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import Img from "../../../../assets/images/creativeDesign.png";
import styles from "../../../../styles/Services.module.css";

const Hero = () => {
  const mobile = useMediaQuery("(max-width:600px)");

  // const customStyle = {
  //   backgroundImage: `url(${Img})`,
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat",
  //   minHeight: mobile ? "100vh" : "150vh",
  //   backgroundPosition: "center bottom",
  //   // position: "absolute",
  //   // top: 0,
  //   marginTop: mobile ? -200 : -300,
  // };

  return (
    <div
    // style={customStyle}
    >
      <div
        id="services-creative-design"
        className={styles.hero}
        style={{
          padding: mobile ? 10 : 40,
          minHeight: "50vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h1>Services</h1>
        <h1>Mobile Apps & Website</h1>
        <h1>Development</h1>

        <p>
          Your internet presence should portray the products and services you
          deliver in a professional, modern and sleek manner to ensure good
          conversions, trust and reputation. Here at Infinate, we deliver mobile
          applications & websites that not only look good and modern but are
          presented in a way that will improve enquires and add profit to your
          bottom line. Infinate has a dedicated team of mobile applications &
          websites developers, designers and SEO professionals to deliver any
          solutions for your online needs. Our team understands how to use
          graphics, coding, SEO and user friendly menus to develop a web
          presence to be proud of.
        </p>
      </div>
    </div>
  );
};

export default Hero;
