import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import styles from "../../../../styles/Services.module.css";
import GradientCard from "../../../../components/GradientCard";

import Img1 from "../../../../assets/images/marketing/symbol1.svg";
import Img2 from "../../../../assets/images/marketing/symbol2.svg";
import Img3 from "../../../../assets/images/marketing/symbol3.svg";
import Img4 from "../../../../assets/images/marketing/symbol4.svg";
import Img5 from "../../../../assets/images/marketing/symbol5.svg";
import Img6 from "../../../../assets/images/marketing/symbol6.svg";

const SocialMediaManagement = () => {
  const mobile = useMediaQuery("(max-width:600px)");

  const content = [
    {
      id: 1,
      image: Img1,
      text: "Create an overarching social strategy for the brand, including social voice and persona, target audiences, competitive landscape, channel strategies, key messaging pillars and visual identity.",
    },
    {
      id: 2,
      image: Img2,
      text: "Draft and execute monthly content calendars.",
    },
    {
      id: 3,
      image: Img3,
      text: "Establish community management guidelines and respondto customer service inquiries.",
    },
    {
      id: 4,
      image: Img4,
      text: "Ongoing photo and video creation and/or utilizeexisting assets to develop social-first creative.",
    },
    {
      id: 5,
      image: Img5,
      text: "Host and facilitate giveaways and creative campaigns",
    },
    {
      id: 6,
      image: Img6,
      text: "Build relationships with influencers and brands forco-promotion campaigns and posts.",
    },
  ];

  return (
    <Grid container alignItems="center">
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <div className={styles.content}>
          <h1>Social Media Management</h1>
          <p>
            Social media has quickly become one of the most important means of
            connecting with the audience who not only want your brand or
            service, but are ready and willing to spread the word on the behalf
            of your brand. Our team’s expertise in Social media effectively
            harnesses creativity and technology within the social space allowing
            growing your social community using strategic content, and
            establishing campaigns that reinforce brand messaging while
            highlighting initiatives, products, services, events and much more.
            We will work directly with your marketing team to create an
            integrated program that aligns with brand initiatives throughout the
            year. In managing social platform content, we can take care of the
            following activities:
          </p>
        </div>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
          {content.map((section) => (
            <GradientCard imgSrc={section.image} content={section.text} />
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default SocialMediaManagement;
