import React from "react";
import { Grid } from "@mui/material";
import styles from "../../../../styles/Services.module.css";
import Img from "../../../../assets/images/servicesProductLaunching.png";
const ProductLaunching = () => {
  return (
    <Grid
      container
      className={styles.servicesProductLaunchingSection}
      alignItems="flex-end"
    >
      <Grid
        item
        xl={7}
        lg={7}
        md={7}
        sm={12}
        xs={12}
        sx={{}}
        order={{ xs: 2, sm: 2, md: 1, lg: 1, xl: 1 }}
      >
        <div className={styles.productLaunchingContent}>
          <h1>Product Launching</h1>
          <p>
            We specialize in transforming your ideas, and needs into pioneering,
            sellable products and successful brands. We will help you in all
            phases of the product launch. We leverage our creative departments’
            abilities to discover realistic solutions to complex challenges
            which are vital to the product launching phase.
          </p>
        </div>
      </Grid>

      <Grid
        item
        xl={5}
        lg={5}
        md={5}
        sm={12}
        xs={12}
        sx={{
          marginTop: { xs: 10, sm: 10, md: 0, lg: 0, xl: 0 },
          marginBottom: { xs: 10, sm: 10, md: 0, lg: 0, xl: 0 },
        }}
        order={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
      >
        <img src={Img} />
      </Grid>
    </Grid>
  );
};

export default ProductLaunching;
